import React from 'react';
import './AdsTechBlock.css';
// import logo from './img/AdsTechBlock_logo.png'; 

const AdsTechBlock = () => {
  return (
    <div className="ads-tech-block" id='atb'>
      <header className="header">
        <div className="about">О нас</div>
        <div className="title">
          Мы - <span className="highlight">AdsTech Solutions</span>
        </div>
      </header>
      <main className="main-content">
  
        <div className="description">
          <p> 
            {/* <img img src={logo} alt="" className="logo"/> */}
          {/* <img src={logo} alt="AdsTech Solutions Logo" className="logo" /> */}
          Занимаемся цифровым маркетингом.<br/>
          В нашем управлении — более 50 веб-сайтов,<br/>
          включая 15 ведущих брендов-сравнений на разных языках.<br/>
          Привлекаем аудиторию, охватываем новости, сравниваем<br/>
          и обозреваем товары и услуги —<br/>
          и все это с <span className="highlight">безупречной маркетинговой точностью.</span></p>
        </div>
      </main>
      <footer className="footer">
        <div className="statistic">
          <span className="number">8</span> <span className="white_text">лет опыта</span>
        </div>
        <div className="statistic">
          <span className="number">50+</span> <span className="white_text">проектов</span>
        </div>
        <div className="statistic">
          <span className="number">200+</span> <span className="white_text">людей в команде</span>
        </div>
      </footer>
    </div>
  );
};

export default AdsTechBlock;
