import React, { useState } from "react";
import "./ContactBlock.css";

const ContactBlock = () => {
  const [isPressed, setIsPressed] = useState(false);

  const handleButtonClick = () => {
    setIsPressed(true);
    setTimeout(() => setIsPressed(false), 300); // Возвращаем в исходное состояние через 300 мс
  };

  return (
    <div className="contact-container" id="contact_block">
      <h2 className="contact-title">СВЯЖИСЬ С НАМИ</h2>
      <div className="contact-info">
        <span className="contact-email">hello@adtechsolution.agency</span>
      </div>
      <div className="buttons">
        <button className="custom-button">Инста</button>
        <button className="custom-button">Телега</button>
        <button className="custom-button">ВК</button>
      </div>
    </div>
  );
};

export default ContactBlock;
