import React from 'react';
import './Header.css';
import Typewriter from '../typewriter/Typewriter'; // Импортируем компонент для печатания текста
import DateDisplay from '../date/DateDisplay'; // Импортируем компонент для отображения даты и времени

const Header = () => {
  return (
    <div className="header-container">
      <header className="header">
        <svg className="logo left-logo" viewBox="0 0 389.6 453.5">
          <path className="cls-1" d="M269.21,95.04l-122.67-7.06-4.7-78.36L263.69,0l5.53,95.04ZM163.35,107.45l-112.81-18.5-22.28,114.07,142.8,24.29-7.72-119.86ZM205.66,319.65l-85.53-73.43L0,377.26l84.66,76.24,121-133.85ZM348.22,194.59l-45.01-107.4-200.38,88.41,39.01,99.33,206.38-80.34ZM321.05,225.45l-80.2,37.3,40.53,161.76,86.66-18.73-46.98-180.33ZM389.6,403.46l-28.14-76.02-152.33,44.29,22.22,76.03,158.25-44.3Z" />
        </svg>
      
        <svg className="logo right-logo" viewBox="0 0 1253.42 320.06">

<g id="logo">
  <rect className="cls-3" y="30.79" width="1253.42" height="289.26"/>
  <text className="cls-1" transform="translate(61.65 234.54)"><tspan className="cls-4" x="0" y="0">s</tspan><tspan className="cls-5" x="109.6" y="0">o</tspan><tspan className="cls-2" x="255.63" y="0">l</tspan><tspan className="cls-5" x="376.71" y="0">utio</tspan><tspan className="cls-2" x="866.74" y="0">n</tspan><tspan className="cls-5" x="1013.35" y="0">s</tspan></text>
</g>
</svg>
     
      </header>
      <div className="content" id="header">
        <div>
        <div className="typewriter-container">
          <Typewriter text="Привет. Мы запускаем рекламное продвижение для любых проектов" speed={100} cursorBlinkCount={5} />
        </div>
        <DateDisplay />
        </div>
        <div className="buttons">
          <a href="#contact_block" className="custom-button">Контакты</a>
          <a href="#lb" className="custom-button">Услуги</a>
          <a href="#job" className="custom-button">Карьера</a>
          <a href="#atb" className="custom-button">О нас</a>
        </div>
      </div>
    </div>
  );
};

export default Header;